<template>
  <!-- index START -->
  <div class="index">
    INDEX

    <!-- form:login:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:login:loading END -->
  </div>
  <!-- index END -->
</template>

<script>
export default {
  name: 'index',
  components: {
    LoadingDefault: () => import('../components/shared/loading/Default.vue'),
  },
  data() {
    return {
      /**
       * Box
       */
      boxID: this.$route.query ? this.$route.query.boxID : undefined,
      boxLang: this.$route.query ? this.$route.query.locale : undefined,
      box: null,

      /**
       * Loading
       */
      loadingVisible: true,
      loadingHeadline: this.$t('sms.loading.form.headline'),
      loadingExcerpt: this.$t('sms.loading.form.excerpt'),

      /**
       * Redirect
       */
      count: 5,
    };
  },
  async created() {
    if (this.boxID !== undefined) {
      this.box = await this.FETCH_BOX();
      // redirect if box id exists, but sms check in not
      if (this.box && this.box.hasSmsCheckin === 0) {
        if (this.box.hasAppCheckin === 1) {
          this.$router.push('/apps');
        } else {
          this.loadingVisible = false;
        }
      } else {
        this.$router.push({ name: 'sms-check-in', query: { boxID: this.boxID, boxLang: this.boxLang } });
      }
    } else {
      this.loadingVisible = false;
    }
    // alert(JSON.stringify(this.$device));
  },
  methods: {
    async FETCH_BOX() {
      await this.$store.dispatch('setBoxSms', {
        payload: {
          id: this.boxID,
        },
      });
      return this.$store.getters.boxSms;
    },
  },
};
</script>
